// Type specifiers
export const TYPE_INTEGER                       = 'i';
export const TYPE_INTEGER_BINARY                = 'b';
export const TYPE_INTEGER_ASCII_CHARACTER       = 'c';
export const TYPE_INTEGER_DECIMAL               = 'd';
export const TYPE_INTEGER_OCTAL                 = 'o';
export const TYPE_INTEGER_UNSIGNED_DECIMAL      = 'u';
export const TYPE_INTEGER_HEXADECIMAL           = 'x';
export const TYPE_INTEGER_HEXADECIMAL_UPPERCASE = 'X';
export const TYPE_FLOAT_SCIENTIFIC              = 'e';
export const TYPE_FLOAT_SCIENTIFIC_UPPERCASE    = 'E';
export const TYPE_FLOAT                         = 'f';
export const TYPE_FLOAT_SHORT                   = 'g';
export const TYPE_FLOAT_SHORT_UPPERCASE         = 'G';
export const TYPE_STRING                        = 's';

// Simple literals
export const LITERAL_PERCENT           = '%';
export const LITERAL_SINGLE_QUOTE      = "'";
export const LITERAL_PLUS              = '+';
export const LITERAL_MINUS             = '-';
export const LITERAL_PERCENT_SPECIFIER = '%%';

// Radix constants to format numbers
export const RADIX_BINARY      = 2;
export const RADIX_OCTAL       = 8;
export const RADIX_DECIMAL     = 10;
export const RADIX_HEXADECIMAL = 16;